/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Button from 'elements/Button';
import logo from 'assets/images/Jaguar-new.jpeg';

export default function BrandIcon() {
  return (
    <Button
      className=""
      type="link"
      href="/"
    >
      <p className="text-theme-blue text-4xl">
        <img src={logo} alt="Jaguar" className="w-15 h-12 inline-block logo" />
        Jaguar
        <span className="theme-color-red">Solutions</span>
      </p>
    </Button>
  );
}
