/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Fade from 'react-reveal/Fade';
import Team from 'assets/images/JAG.jpeg';
// import Data from 'json/landingPage.json';

export default function HeroTeam() {
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <h1 className="text-6xl text-theme-blue font-bold leading-tight mb-5">
            Our Services
          </h1>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            Our Services are designed to help you grow your business.
            We are a team of talented developers and designers who are ready to help
            you build your dream product.
          </p>
          {/* <ul className="mt-5">
            {
              Data.service.map((item) => (
                <li key={item.id} className="flex items-center text-theme-blue text-lg">
                  <svg className="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                  {item.title}
                </li>
              ))
            } */}
          {/* </ul> */}
        </div>
        <div className="w-full sm:w-1/2 sm:pr-12">
          <img src={Team} alt="Hero" />
        </div>
      </Fade>
    </section>
  );
}
