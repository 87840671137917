/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Fade from 'react-reveal/Fade';
import Team from 'assets/images/team.png';
// import Data from 'json/landingPage.json';

export default function HeroTeam() {
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <Fade bottom>
        <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
          <h1 className="text-6xl text-theme-blue font-bold leading-tight mb-5">
            About Us
          </h1>
          <p className="font-light text-xl text-gray-400 leading-relaxed">
            We are a team of professionals who are ready to help you scale up your business.
            We are ready to help you with our great service. We provide the best service in
            terms of quality and speed. We have been trusted by many clients and have been
            proven to be the best in our field. We are ready to help you with your business
            needs. We are ready to help you with your business needs.
          </p>
        </div>
        <div className="w-full sm:w-1/2 sm:pr-12">
          <img src={Team} alt="Hero" />
        </div>
      </Fade>
    </section>
  );
}
